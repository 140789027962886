import { Injectable } from '@angular/core';

import { Store, Action } from '@ngrx/store';

@Injectable()
export class WidgetFacade {

	constructor(private store: Store<never>) {
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
