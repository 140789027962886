<ng-container *ngIf="!initializing">
    <header class="margin-horizontal">
        <div class="mat-body">
            {{ 'Widget.Insurance.Title' | translate }}
            <ng-container *ngIf="widget?.insurance" [ngTemplateOutlet]="check_circle_outline"></ng-container>
        </div>

        <mat-button-toggle-group class="block" color="primary">
            <mat-button-toggle color="primary" *ngFor="let type of insuranceTypes" [value]="type" [checked]="type === widget?.insurance"
                               (click)="setInsurance(type)">
                {{ 'Widget.Insurance.Type.' + type | translate }}
            </mat-button-toggle>
        </mat-button-toggle-group>
        <ng-container *ngIf="!!filteredCategoryList.length">
            <div class="mat-body">
                {{ 'Widget.Category' | translate }}
                <ng-container *ngIf="widget?.category" [ngTemplateOutlet]="check_circle_outline"></ng-container>
            </div>

            <mat-form-field appearance="outline">
                <mat-select (selectionChange)="setCategory($event.value)" placeholder="{{'Widget.Select_Label' | translate}}"
                            [value]="!!widget.category ? TreatmentUtils.getPublicCategoryById(categoryList, widget.category.id) : null"
                            [placeholder]="'Widget.Select_Label' | translate">
                    <mat-option *ngFor="let category of filteredCategoryList" [value]="category">
                        {{ category.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="treatmentList">
            <div class="mat-body">
                {{ 'Widget.Treatment' | translate }}
                <ng-container *ngIf="widget?.treatment" [ngTemplateOutlet]="check_circle_outline"></ng-container>
            </div>

            <mat-form-field appearance="outline">
                <mat-select (selectionChange)="setTreatment($event.value)" placeholder="{{'Widget.Select_Label' | translate}}"
                            [value]="!!widget.treatment ? TreatmentUtils.getPublicTreatmentById(treatmentList, widget.treatment.id) : null"
                            [placeholder]="'Widget.Select_Label' | translate">
                    <mat-option *ngFor="let treatment of treatmentList" [value]="treatment">
                        {{ treatment.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="!!hasResourceList">
            <div class="mat-body">
                {{ 'Widget.Resource' | translate }}<span>({{ 'Widget.Optional' | translate }})</span>
                <ng-container *ngIf="widget?.resource" [ngTemplateOutlet]="check_circle_outline"></ng-container>
            </div>
            <mat-form-field appearance="outline">
                <mat-select (selectionChange)="setResource($event.value)" placeholder="{{'Widget.Resource_All' | translate}}"
                            [value]="!!widget.resource ? ResourceUtils.getPublicResourceById(resourceList, widget.resource.id) : null"
                            [disabled]="!widget?.insurance || !widget?.treatment || resourceList.length < 1">
                    <mat-option>{{'Widget.Resource_All' | translate}}</mat-option>
                    <mat-option *ngFor="let resource of resourceList" [value]="resource">
                        {{ ResourceUtils.displayPublicResourceName(resource, true) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>


        <ng-container *ngIf="waitingList && step === 1">
            <div class="mat-body">
                {{ 'Widget.Select_Appointment_Period' | translate }}
                <ng-container *ngIf="widget?.resource" [ngTemplateOutlet]="check_circle_outline"></ng-container>
            </div>
            <button mat-stroked-button class="date-picker" (click)="picker.open()" disableRipple="true">
                <div class="text" [innerHTML]="getRequestDateRange()"></div>
                <div matSuffix class="push-top">
                    <ng-container [ngTemplateOutlet]="calendar"></ng-container>
                </div>
            </button>

            <mat-form-field appearance="outline" class="hidden">
                <mat-date-range-input [formGroup]="rangeWaitingDates" [rangePicker]="picker" [min]="today" [max]="intervalRangeMax">
                    <input matStartDate formControlName="start">
                    <input matEndDate formControlName="end">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker [panelClass]="'range-selector'" color="primary">
                    <mat-datepicker-actions>
                        <div class="aside">
                            <div class="wrapper">
                                <div class="title mat-button" MatDatepickerActions>{{ 'Widget.Period.Title' | translate | uppercase }}</div>
                                <button mat-button color="primary" (click)="requestDateChange(1)" MatDatepickerActions>{{ 'Widget.Period.This_Week' | translate | uppercase }}</button>
                                <button mat-button color="primary" (click)="requestDateChange(2)" MatDatepickerActions>{{ 'Widget.Period.Next_Week' | translate | uppercase }}</button>
                                <button mat-button color="primary" (click)="requestDateChange(3)" MatDatepickerActions>{{ 'Widget.Period.Next_Two_Weeks' | translate | uppercase }}</button>
                                <button mat-button color="primary" (click)="requestDateChange(4)" MatDatepickerActions>{{ 'Widget.Period.This_Month' | translate | uppercase }}</button>
                                <button mat-button color="primary" (click)="requestDateChange(5)" MatDatepickerActions>{{ 'Widget.Period.Next_Month' | translate | uppercase }}</button>
                            </div>
                        </div>

                        <button mat-button color="warn" (click)="requestDateChange(0)" MatDatepickerActions>{{ 'Widget.Button.Delete' | translate | uppercase }}</button>

                        <button mat-button color="primary" matDatepickerCancel>
                            {{ 'Widget.Button.Cancel' | translate | uppercase }}
                        </button>
                        <button mat-raised-button color="primary" matDatepickerApply>
                            {{ 'Widget.Button.Apply' | translate | uppercase }}
                        </button>
                    </mat-datepicker-actions>
                </mat-date-range-picker>
            </mat-form-field>

            <div class="mat-body">
                {{ 'Widget.Request.Time.Title' | translate }}
                <ng-container *ngIf="rangeWaitingTimes.valid && rangeWaitingTimes.dirty" [ngTemplateOutlet]="check_circle_outline"></ng-container>
            </div>
            <div class="block flex" [formGroup]="rangeWaitingTimes">
                <div class="block">
                    <div class="mat-body">{{ 'Widget.Request.Time.From' | translate }}</div>
                    <mat-form-field appearance="outline" class="block">
                        <input matInput appAutocorrectTime placeholder="HH:mm" minlength="4" maxlength="5" formControlName="from" autocomplete="off"
                               (change)="requestTimeUpdate(rangeWaitingTimes.get('from'))">
                        <div matSuffix>
                            <ng-container [ngTemplateOutlet]="time"></ng-container>
                        </div>
                    </mat-form-field>
                </div>

                <div class="margin"><br></div>

                <div class="block">
                    <div class="mat-body">{{ 'Widget.Request.Time.To' | translate }}</div>
                    <mat-form-field appearance="outline" class="block">
                        <input matInput appAutocorrectTime placeholder="HH:mm" minlength="4" maxlength="5" formControlName="to" autocomplete="off"
                               (change)="requestTimeUpdate(rangeWaitingTimes.get('to'))">
                        <div matSuffix>
                            <ng-container [ngTemplateOutlet]="time"></ng-container>
                        </div>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
    </header>

    <ng-container *ngIf="step === 0">
        <main>
            <div class="overlay" *ngIf="loading">
                <div class="loading"></div>
            </div>

            <button mat-icon-button color="primary" class="nav prev" (click)="moveToPrev()" [disabled]="loading || disablePrev">
                <ng-container [ngTemplateOutlet]="chevron_left"></ng-container>
            </button>

            <button mat-icon-button color="primary" class="nav next" (click)="moveToNext()" [disabled]="loading || !hasSlotsAvailable">
                <ng-container [ngTemplateOutlet]="chevron_right"></ng-container>
            </button>

            <div class="list-wrapper">
                <div class="list header">
                    <div class="item" *ngFor="let day of daysOfWeek; let i = index;"
                         [ngClass]="{'disabled' : firstDayAvailable || (slotList?.length && slotList[i] && slotList[i]['slots'].length < 1)}">
                        <strong>{{ day | date: 'EE' : locale_id : locale_id }}</strong>
                        {{ day | date: 'dd MMM' : locale_id : locale_id }}
                    </div>
                </div>
            </div>

            <div class="divider"></div>

            <div class="list-wrapper list-container"
                 [ngClass]="{'all' : showAll, 'without-resource' : !hasResourceList, 'without-treatment': !hasTreatmentList,'disabled': !widget?.insurance || (!!hasTreatmentList && !widget?.treatment)}">
                <div class="list content" *ngFor="let day of daysOfWeek; let i = index;">
                    <ng-container *ngIf="hasSlotsAvailable && !firstDayAvailable && !!slotList?.length && slotList[i]">
                        <div class="item" *ngFor="let slot of slotList[i]['slots']; let iSlot = index;">
                            <ng-container *ngIf="getSlotId(i, iSlot)=== selectedSlot; then button else buttonSelected"></ng-container>
                            <ng-template #button>
                                <button mat-raised-button color="primary" (click)="slotBooking(day, slot, getSlotId(i, iSlot))"
                                        [disabled]="!widget?.insurance || (!!hasTreatmentList && !widget?.treatment)">
                                    {{ slot.startTime }}
                                </button>
                            </ng-template>
                            <ng-template #buttonSelected>
                                <button mat-stroked-button color="primary" (click)="slotBooking(day, slot, getSlotId(i, iSlot))"
                                        [disabled]="!widget?.insurance || (!!hasTreatmentList && !widget?.treatment)">
                                    {{ slot.startTime }}
                                </button>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="divider"></div>

            <button type="button" mat-raised-button color="primary" class="floating" *ngIf="hasSlotsAvailable && firstDayAvailable"
                    (click)="moveToDate(firstDayAvailable)">
                {{ 'Widget.Button.Next_Appointment_On' | translate | uppercase }} {{firstDayAvailable | date: 'dd. MMMM' : locale_id : locale_id }}
            </button>
            <div class="hint no-appointment" *ngIf="!hasSlotsAvailable" [innerHTML]="'Widget.No_Appointment_Available' | translate | uppercase"></div>
        </main>

        <nav class="no-padding margin-top margin-bottom-S" *ngIf="!showAll" [ngClass]="{'hidden': !showAllVisibility}">
            <button mat-button color="primary" *ngIf="hasSlotsAvailable"
                    [disabled]="loading || !widget?.insurance"
                    (click)="showAll = !showAll">
                {{ 'Widget.Button.Show_More' | translate | uppercase }}
            </button>
        </nav>

        <footer class="clearfix">
            <nav class="no-padding" *ngIf="!hasSlotsAvailable && contact">
                <a mat-raised-button color="primary" href="tel:contact" target="_blank">{{ 'Widget.Button.Call_Us_By_Phone' | translate | uppercase }}</a>
            </nav>

            <ng-container *ngIf="hasSlotsAvailable">
                <div class="hint">
                    {{ !(widget?.insurance && !!(!!hasTreatmentList || !widget?.treatment)) || widget?.start || firstDayAvailable ? ' ' : 'Widget.Select_Slot' | translate }}
                </div>

                <button type="button" mat-raised-button color="primary" (click)="openBooking()"
                        [disabled]="loading || !(widget?.insurance && !!(!!hasTreatmentList || !widget?.treatment)) || !widget?.start">
                    {{ 'Widget.Button.Make_Appointment' | translate | uppercase }}
                </button>
            </ng-container>
        </footer>
    </ng-container>

    <ng-container *ngIf="step === 1">
        <main class="request">
            <div class="overlay" *ngIf="loading">
                <div class="loading"></div>
            </div>
            <ng-container *ngIf="rangeWaitingDates.valid && rangeWaitingDates.dirty && hasSlotsAvailable; else request">
                <div class="hint size-x2">
                    <ng-container [ngTemplateOutlet]="calendar_ok"></ng-container>
                    {{ 'Widget.Request.Hint' | translate }}
                </div>

                <button mat-button color="primary" class="margin-top" (click)="stepSlot()">
                    {{ 'Widget.Request.Button' | translate | uppercase }}
                </button>
            </ng-container>

            <ng-template #request>
                <div class="text-center">
                    <button mat-button color="primary" class="margin-top" (click)="openRequest()"
                            [class.mat-raised-button]="!(loading || !(widget?.insurance && widget?.treatment) || (!!widget?.start || rangeWaitingDates.invalid))"
                            [disabled]="loading || !(widget?.insurance && widget?.treatment) || (!!widget?.start || rangeWaitingDates.invalid || rangeWaitingTimes.invalid)">
                        {{ 'Widget.Button.Request_Appointment' | translate | uppercase }}
                    </button>
                </div>
            </ng-template>
        </main>
    </ng-container>

    <!-- SPECIAL FEATURE -->
    <ng-container *ngIf="waitingList">
        <footer class="clearfix">
            <div class="divider margin-top"></div>

            <ng-container *ngIf="step === 1">
                <button mat-button color="primary" class="margin-top" (click)="stepSlot()">
                    <ng-container [ngTemplateOutlet]="arrow_left"></ng-container>
                    {{ 'Widget.Button.Back' | translate | uppercase }}
                </button>
            </ng-container>
            <ng-container *ngIf="step === 0">
                <div class="mat-body margin-top">{{ 'Widget.Request.Title' | translate }} </div>
                <button mat-raised-button color="primary" class="margin-top" (click)="step = 1"
                        [disabled]="loading || !(widget?.insurance && widget?.treatment)">
                    {{ 'Widget.Button.Request_Appointment' | translate | uppercase }}
                </button>
            </ng-container>
        </footer>
    </ng-container>

    <div #div></div>
</ng-container>

<div *ngIf="error" class="error">{{'Widget.Error.Text' | translate}}</div>


<ng-template #check_circle_outline>
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
        <path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
    </svg>
</ng-template>

<ng-template #keyboard_arrow_down>
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
    </svg>
</ng-template>

<ng-template #chevron_left>
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
    </svg>
</ng-template>

<ng-template #chevron_right>
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
    </svg>
</ng-template>

<ng-template #arrow_left>
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
        <rect fill="none" height="24" width="24"/>
        <path d="M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z"/>
    </svg>
</ng-template>

<ng-template #calendar>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5z"/>
    </svg>
</ng-template>

<ng-template #calendar_ok>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V9h14v10zM5 7V5h14v2H5zm5.56 10.46l5.93-5.93-1.06-1.06-4.87 4.87-2.11-2.11-1.06 1.06z"/>
    </svg>
</ng-template>

<ng-template #time>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
    </svg>
</ng-template>
